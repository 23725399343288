export const baseUrl = process.env.REACT_APP_BARE_URL;
export const authUrl = process.env.REACT_APP_BASE_AUTH_URL;
export const saveContactUrl = `${baseUrl}/savecontact`;
export const getContactQueriesUrl = `${baseUrl}/getcontactqueries`;
export const testUrl = `${baseUrl}/testdata`;
export const saveNewPartyDataUrl = `${baseUrl}/savenewpartydata`;
export const getCompanyNamesUrl = `${baseUrl}/getcompanynames`;
export const saveDocketDataUrl = `${baseUrl}/savedocketdata`;
export const getDocketsUrl = `${baseUrl}/getdockets`;
export const getDataForInvoiceUrl = `${baseUrl}/getdataforinvoice`;
export const getDataToUpadateUrl = `${baseUrl}/getdatatoupadate`;
export const updateDocketDataUrl = `${baseUrl}/updatedocketdata`;
export const updateRateListUrl = `${baseUrl}/updateratelist`;
export const getInvoiceNumUrl = `${baseUrl}/getinvoicenum`;
export const registerUserUrl = `${authUrl}/register`;
export const verifyEmailUrl = `${authUrl}/verify`;
export const signinUserUrl = `${authUrl}/signin`;
export const signoutUserUrl = `${authUrl}/signout`;
export const getuserUrl = `${authUrl}/getuser`;
export const forgotPasswordUrl = `${authUrl}/forgotPassword`;
export const updatePasswordUrl = `${authUrl}/updatePassword`;
